<template>
  <HeroBg
    heroTitle="Build High performing remote teams with <br class='hidden xl:inline-block' /> handpicked, world-class talent"
    heroDesc="Our Talentpool makes hiring great talent fast and easy. Our unified platform provides you with complete access to talent profiles. Create your account, post a job (or several), and start browsing qualified candidates instantly."
    heroBtnText="Start Hiring"
    :heroBtnLink="$employerUrl + '/register'"
    heroImg="hero9.png"
  />

  <Customers />

  <div class="flex justify-center bg-brand-black relative overflow-hidden">
    <div class="absolute inset-0 bg-gradient-to-b from-brand-black/50 to-brand-black"></div>

    <div class="w-full lg:w-[65vw] px-[24px] md:px-0 relative z-10">
      <div class="py-[5rem]">
        <div class="max-w-[800px] mx-auto text-center mb-16">
          <div class="inline-block px-4 py-2 bg-white/10 rounded-full mb-4">
            <span class="text-[14px] font-medium text-white/90">Simple Process</span>
          </div>

          <h2 class="text-[32px] md:text-[56px] text-white leading-[110%] font-semibold mb-6">
            How it <span class="text-brand-primary">works</span>
          </h2>

          <p class="text-[16px] md:text-[18px] text-white/80 leading-[150%] max-w-[600px] mx-auto">
            Get started with our streamlined hiring process in just a few simple steps
          </p>
        </div>

        <div class="grid md:grid-cols-3 gap-6 md:gap-8 pb-5">
          <div class="group relative">
            <div
              class="absolute inset-0 bg-gradient-to-r from-brand-primary/20 to-brand-primary/10 rounded-xl transform transition-transform duration-300 group-hover:scale-105"
            ></div>
            <div class="bg-white rounded-xl p-8 transition-all duration-300 group-hover:translate-y-[-4px] relative">
              <div class="text-center">
                <div
                  class="w-16 h-16 bg-brand-primary/10 rounded-xl flex items-center justify-center mx-auto mb-6 transform transition-transform duration-300 group-hover:scale-110"
                >
                  <img src="@/assets/icons/users.svg" class="w-8 h-8" alt="Create jobs icon" />
                </div>
                <h3 class="font-semibold text-[20px] text-brand-black mb-3">Create unlimited jobs</h3>
                <p class="text-[14px] text-gray-600 leading-relaxed">
                  Post as many job openings as you need with detailed descriptions and requirements
                </p>
              </div>
            </div>
          </div>

          <div class="group relative">
            <div
              class="absolute inset-0 bg-gradient-to-r from-brand-primary/20 to-brand-primary/10 rounded-xl transform transition-transform duration-300 group-hover:scale-105"
            ></div>
            <div class="bg-white rounded-xl p-8 transition-all duration-300 group-hover:translate-y-[-4px] relative">
              <div class="text-center">
                <div
                  class="w-16 h-16 bg-brand-primary/10 rounded-xl flex items-center justify-center mx-auto mb-6 transform transition-transform duration-300 group-hover:scale-110"
                >
                  <img src="@/assets/icons/users.svg" class="w-8 h-8" alt="Browse candidates icon" />
                </div>
                <h3 class="font-semibold text-[20px] text-brand-black mb-3">Browse qualified candidates</h3>
                <p class="text-[14px] text-gray-600 leading-relaxed">Access our pool of pre-vetted candidates matching your requirements</p>
              </div>
            </div>
          </div>

          <div class="group relative">
            <div
              class="absolute inset-0 bg-gradient-to-r from-brand-primary/20 to-brand-primary/10 rounded-xl transform transition-transform duration-300 group-hover:scale-105"
            ></div>
            <div class="bg-white rounded-xl p-8 transition-all duration-300 group-hover:translate-y-[-4px] relative">
              <div class="text-center">
                <div
                  class="w-16 h-16 bg-brand-primary/10 rounded-xl flex items-center justify-center mx-auto mb-6 transform transition-transform duration-300 group-hover:scale-110"
                >
                  <img src="@/assets/icons/users.svg" class="w-8 h-8" alt="Assess skills icon" />
                </div>
                <h3 class="font-semibold text-[20px] text-brand-black mb-3">Assess skills and experience</h3>
                <p class="text-[14px] text-gray-600 leading-relaxed">Evaluate candidates through our comprehensive assessment tools</p>
              </div>
            </div>
          </div>

          <div class="group relative md:col-start-2">
            <div
              class="absolute inset-0 bg-gradient-to-r from-brand-primary/20 to-brand-primary/10 rounded-xl transform transition-transform duration-300 group-hover:scale-105"
            ></div>
            <div class="bg-white rounded-xl p-8 transition-all duration-300 group-hover:translate-y-[-4px] relative">
              <div class="text-center">
                <div
                  class="w-16 h-16 bg-brand-primary/10 rounded-xl flex items-center justify-center mx-auto mb-6 transform transition-transform duration-300 group-hover:scale-110"
                >
                  <img src="@/assets/icons/users.svg" class="w-8 h-8" alt="Schedule icon" />
                </div>
                <h3 class="font-semibold text-[20px] text-brand-black mb-3">Schedule Interviews</h3>
                <p class="text-[14px] text-gray-600 leading-relaxed">Easily coordinate and schedule interviews with selected candidates</p>
              </div>
            </div>
          </div>

          <div class="group relative md:col-start-3">
            <div
              class="absolute inset-0 bg-gradient-to-r from-brand-primary/20 to-brand-primary/10 rounded-xl transform transition-transform duration-300 group-hover:scale-105"
            ></div>
            <div class="bg-white rounded-xl p-8 transition-all duration-300 group-hover:translate-y-[-4px] relative">
              <div class="text-center">
                <div
                  class="w-16 h-16 bg-brand-primary/10 rounded-xl flex items-center justify-center mx-auto mb-6 transform transition-transform duration-300 group-hover:scale-110"
                >
                  <img src="@/assets/icons/users.svg" class="w-8 h-8" alt="Hire icon" />
                </div>
                <h3 class="font-semibold text-[20px] text-brand-black mb-3">Hire the right talent instantly</h3>
                <p class="text-[14px] text-gray-600 leading-relaxed">Make informed decisions and hire the best candidates for your team</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-light-yellow relative overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-[#FFE5A3]/20 to-transparent"></div>

    <div class="w-full px-[24px] md:px-0 md:w-[78vw] relative z-10">
      <div class="py-[5rem]">
        <div class="max-w-[900px] mx-auto mb-16">
          <div class="flex justify-center">
            <div class="inline-block px-4 py-2 bg-brand-black/5 rounded-full mb-4">
              <span class="text-[14px] font-medium text-brand-black">Platform Features</span>
            </div>
          </div>

          <h2 class="text-center text-[28px] md:text-[48px] text-brand-black leading-[120%] font-semibold mb-6">
            Key <span class="text-[#B45309]">Benefits</span> for Your Success
          </h2>

          <p class="text-center text-[16px] md:text-[18px] text-[#475467] leading-[150%] max-w-[700px] mx-auto">
            Discover how our platform streamlines your hiring process and helps you find the perfect talent for your team
          </p>
        </div>

        <div class="space-y-6">
          <div
            class="group bg-white hover:bg-[#FFF8E7] rounded-2xl p-8 md:p-10 transition-all duration-300 border border-brand-black/5 hover:border-brand-black/10"
          >
            <div class="md:flex items-start gap-8">
              <div class="shrink-0">
                <div
                  class="w-[72px] h-[72px] md:w-[90px] md:h-[90px] rounded-2xl bg-[#FFF8E7] group-hover:bg-white flex justify-center items-center mb-6 md:mb-0 transition-all duration-300 group-hover:scale-105"
                >
                  <img
                    src="@/assets/images/icons/price2.png"
                    class="w-[42px] h-[42px] md:w-[52px] md:h-[52px] transition-transform duration-300"
                    alt="Talent search icon"
                  />
                </div>
              </div>

              <div class="flex-1">
                <h3 class="text-[20px] md:text-[26px] font-semibold text-brand-black mb-3">Find the Right Talent with Ease</h3>
                <p class="text-[14px] md:text-[16px] leading-[160%] text-[#475467]">
                  Experience complete transparency throughout your hiring journey. Our platform provides detailed insights before, during, and after
                  the hiring process, while our team handles all the groundwork to ensure you find the perfect match.
                </p>
              </div>
            </div>
          </div>

          <div
            class="group bg-white hover:bg-[#FFF8E7] rounded-2xl p-8 md:p-10 transition-all duration-300 border border-brand-black/5 hover:border-brand-black/10"
          >
            <div class="md:flex items-start gap-8">
              <div class="shrink-0">
                <div
                  class="w-[72px] h-[72px] md:w-[90px] md:h-[90px] rounded-2xl bg-[#FFF8E7] group-hover:bg-white flex justify-center items-center mb-6 md:mb-0 transition-all duration-300 group-hover:scale-105"
                >
                  <img
                    src="@/assets/images/icons/therapy.png"
                    class="w-[42px] h-[42px] md:w-[52px] md:h-[52px] transition-transform duration-300"
                    alt="Process management icon"
                  />
                </div>
              </div>

              <div class="flex-1">
                <h3 class="text-[20px] md:text-[26px] font-semibold text-brand-black mb-3">Streamlined Hiring Process</h3>
                <p class="text-[14px] md:text-[16px] leading-[160%] text-[#475467]">
                  Manage your entire recruitment process in one centralized location. Collaborate with your team, communicate with candidates, and
                  make informed decisions through our intuitive platform designed for efficiency.
                </p>
              </div>
            </div>
          </div>

          <div
            class="group bg-white hover:bg-[#FFF8E7] rounded-2xl p-8 md:p-10 transition-all duration-300 border border-brand-black/5 hover:border-brand-black/10"
          >
            <div class="md:flex items-start gap-8">
              <div class="shrink-0">
                <div
                  class="w-[72px] h-[72px] md:w-[90px] md:h-[90px] rounded-2xl bg-[#FFF8E7] group-hover:bg-white flex justify-center items-center mb-6 md:mb-0 transition-all duration-300 group-hover:scale-105"
                >
                  <img
                    src="@/assets/images/icons/therapy.png"
                    class="w-[42px] h-[42px] md:w-[52px] md:h-[52px] transition-transform duration-300"
                    alt="Performance icon"
                  />
                </div>
              </div>

              <div class="flex-1">
                <h3 class="text-[20px] md:text-[26px] font-semibold text-brand-black mb-3">Continuous Performance Assessment</h3>
                <p class="text-[14px] md:text-[16px] leading-[160%] text-[#475467]">
                  Drive excellence through our comprehensive assessment tools. Track performance, provide constructive feedback, and ensure continuous
                  improvement with our data-driven approach to talent development.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <CreateAccount />
</template>

<script setup>
import Customers from '@/components/partials/Customers.vue';
import HeroBg from '@/components/partials/HeroBg.vue';
import CreateAccount from '@/components/partials/CreateAccount.vue';
import { useMeta } from '@/utils/meta';
useMeta();
</script>
